@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Nunito', 'Nanum Gothic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  color: var(--primary-color);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:active {
  text-decoration: underline;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
}