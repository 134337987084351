.problem-detail-page {
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.back-button, .delete-button {
  font-size: 24px;
  color: var(--primary-color);
  background: none;
  border: none;
  cursor: pointer;
}

.problem-info {
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 15px;
}

.problem-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.math-content {
  font-size: 16px;
  line-height: 1.6;
  overflow-x: auto;
}

.problem-info h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.problem-info p {
  margin-bottom: 10px;
}