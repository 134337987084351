.saved-list-page {
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-color);
}

.nav-button, .edit-mode-button {
  padding: 8px 15px;
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.selection-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.selection-actions button {
  flex: 1;
  min-width: 45%;
  padding: 8px 12px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

.problem-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}