.main-page {
  padding: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 24px;
  font-weight: bold;
}

.list-icon-button {
  font-size: 24px;
  color: var(--primary-color);
  background: none;
  border: none;
}

.upload-section {
  margin-bottom: 20px;
}

.loading-container {
  text-align: center;
  margin: 20px 0;
}

.loading-message {
  font-size: 18px;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.uploaded-image {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.analysis-result {
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 15px;
  margin-bottom: 20px;
}

.math-content {
  font-size: 16px;
  line-height: 1.6;
  overflow-x: auto;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.saved-problems h2 {
  font-size: 20px;
  margin-bottom: 15px;
}