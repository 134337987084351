:root {
  --primary-color: #FF9800;
  --secondary-color: #4CAF50;
  --background-color: #FFFDE7;
  --text-color: #333333;
  --card-color: #FFFFFF;
  --button-color: #FF9800;
  --button-hover-color: #F57C00;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --font-family: 'Nunito', 'Nanum Gothic', sans-serif;
}

body {
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  margin-top: 0;
}

.button {
  background-color: var(--button-color);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.1s;
  width: 100%;
  text-align: center;
}

.button:hover, .button:active {
  background-color: var(--button-hover-color);
  transform: scale(1.02);
}

.card {
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 15px;
  margin-bottom: 15px;
}