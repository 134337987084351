.upload-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.upload-button {
  width: 100%;
}

.custom-file-upload {
  display: block;
  width: 100%;
  padding: 12px 15px;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.custom-file-upload:active {
  background-color: #45a049;
}