.card {
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.card:active {
  box-shadow: 0 4px 6px var(--shadow-color);
}

.card.selected {
  background-color: #FFF9C4;
}

.card h3 {
  font-size: 18px;
  margin-bottom: 8px;
  color: var(--primary-color);
}

.card p {
  font-size: 14px;
  margin: 5px 0;
}

.checkbox {
  font-size: 20px;
  margin-right: 8px;
  color: var(--secondary-color);
}